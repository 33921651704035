@use 'component/utility' as *;

.wysiwyg {
    font-size: inherit;
    line-height: 1.8;
    word-break: break-all;
    p {}

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
    }

    h1 {
        font-size: 200%;
    }

    h2 {
        font-size: 180%;
    }

    h3 {
        font-size: 160%;
    }

    h4 {
        font-size: 140%;
    }

    h5 {
        font-size: 120%;
    }

    h6 {
        font-size: 100%;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    a {
        text-decoration: underline;
        transition: all 0.3s;
        @include mq(pc){
            &:hover {
                color: $orange;
            }
        }
    }

    // a:hover {
    //     text-decoration: none;
    // }

    strong,
    b {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    sup {
        vertical-align: super;
        font-size: smaller;
    }

    sub {
        vertical-align: sub;
        font-size: smaller;
    }

    code {
        display: block;
        padding: 20px;
        background: #eee;
    }

    ul,
    ol {
        padding-left: 1.5em;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    table {
        width: 100% !important;
        border-collapse: collapse;
        font-size: inherit;

        th,
        td {
            padding: 5px;
            border: 1px solid #ccc;
            text-align: left;
        }

        th {
            font-weight: bold;
        }
    }

    @media screen and (max-width: 768px) {
        .table-wrap {
            overflow: auto;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
                height: 15px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background: #bbb;
            }
        }
    }
}
