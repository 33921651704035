
@keyframes splash-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes splash-fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        pointer-events: none;
    }
}

//bgroop-home

@keyframes bgroop-home-information-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 1003px;
    }
}
@keyframes bgroop-home-information-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -346.06px 0;
    }
}
@keyframes bgroop-home-katsudo-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 681.6px;
    }
}
@keyframes bgroop-home-katsudo-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -236.27px 0;
    }
}
@keyframes bgroop-home-shoplist-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 751.41px;
    }
}
@keyframes bgroop-home-shoplist-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -259.41px 0;
    }
}
@keyframes bgroop-home-areaguide-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 850.98px;
    }
}
@keyframes bgroop-home-areaguide-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -294.57px 0;
    }
}
@keyframes bgroop-home-shopnews-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 879.91px;
    }
}
@keyframes bgroop-home-shopnews-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -303.33px 0;
    }
}
@keyframes bgroop-home-access-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 600.77px;
    }
}
@keyframes bgroop-home-access-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -207.85px 0;
    }
}

//bgroop

@keyframes bgroop-areaguide-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -864.73px 0;
    }
}
@keyframes bgroop-areaguide-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -546.58px 0;
    }
}
@keyframes bgroop-shopinfo-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -1463.01px 0;
    }
}
@keyframes bgroop-shopinfo-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -924.733px 0;
    }
}
@keyframes bgroop-katsudo-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -696px 0;
    }
}
@keyframes bgroop-katsudo-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -439.9245px 0;
    }
}
@keyframes bgroop-shopnews-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -892px 0;
    }
}
@keyframes bgroop-shopnews-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -563.814px 0;
    }
}
@keyframes bgroop-information-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -1014.42px 0;
    }
}
@keyframes bgroop-information-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -641.1886px 0;
    }
}
@keyframes bgroop-special-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -624.7513px 0;
    }
}
@keyframes bgroop-special-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -394.89px 0;
    }
}
@keyframes bgroop-about-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -525.9069px 0;
    }
}
@keyframes bgroop-about-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -332.4129px 0;
    }
}
@keyframes bgroop-notfound-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -1275px 0;
    }
}
@keyframes bgroop-notfound-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -805.8962px 0;
    }
}
@keyframes bgroop-privacy-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -1197px 0;
    }
}
@keyframes bgroop-privacy-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -756.5943px 0;
    }
}
@keyframes bgroop-sitemap-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -648.0095px 0;
    }
}
@keyframes bgroop-sitemap-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -409.5909px 0;
    }
}

@keyframes barAnime{
    0%{
        // height: 0;
        top: -300px;
        // opacity: 0;
    }
    30%{
        // height: 100px;
        // opacity: 1;
    }
    100%{
        // height: 0;
        top: 200px;
        // opacity: 0;
    }
}

@keyframes slide1 {
    0% {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes slide2 {
    0% {
        transform: translateX(0);
    }
    to {
        transform: translateX(-200%);
    }
}

@keyframes infinity-scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
