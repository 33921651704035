//color, weight
$brown: #6A3906;
$gray1: #CDC9C1;
$gray2: #EBE9E6;
$gray3: #8D877E;
$orange: #E94709;

$fw-r: 400;
$fw-m: 500;
$fw-b: 700;

$ff-n: 'Noto Sans JP', sans-serif;
$ff-js: 'Josefin Sans', sans-serif;

@mixin font($sizeFS:13 ,$sizeLH:22) {
    font-size: ($sizeFS) + px;
    line-height: calc($sizeLH / $sizeFS);
}

@mixin ltsp($lt) {
    letter-spacing: calc($lt / 1000) + em;
}

//vw, vh
@function vw($size,$winW:375) {
  @return calc($size / $winW) * 100 + vw;
}
@function vwPC($size,$winW:1366) {
  @return calc($size / $winW) * 100 + vw;
}

@function vh($size,$winH:800) {
    @return calc($size / $winH) * 100 + vh;
}

//breakpoin
$breakpoints: (
    'sp': '(max-width: 768px)',
    'pc': '(min-width: 769px)',
) !default;

@mixin mq($breakpoint: sp) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

//text
@mixin text-lc($lc) {
    display: -webkit-box;
    -webkit-line-clamp: $lc;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

