@use 'component/utility' as *;
@use 'component/anime' as *;

/* ====================================
reset
==================================== */

@use 'component/reset';

/* ====================================
wysiwyg
==================================== */

@use 'component/wysiwyg';

/* ====================================
common style
==================================== */

* {
    box-sizing: border-box;
    word-break: break-all;
}

img {
    max-width: 100%;
}

a {
    // display: block;
    color: inherit;
    @include mq(pc){
        transition: all .3s;
    }
    // text-decoration: underline;
}

.pc-visible {
    @include mq(sp){
        display: none;
    }
}
.sp-visible {
    display: none;
    @include mq(sp){
        display: block;
    }
}

/* ====================================
common parts
==================================== */

body {
    position: relative;
    font-family: $ff-n;
    font-weight: $fw-m;
    font-size: 14px;
    letter-spacing: 0.08em;
    color: $brown;
    background: #FFF6ED;
}

.g-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    width: 100%;
    height: 74px;
    padding: 20px 40px 0;
    @include mq(sp){
        height: 50px;
        padding: 17px 20px 0 16px;
        // pointer-events: none;
    }
    &__logo {
        .logo {
            width: 173px;
            height: 53px;
            @include mq(sp){
                width: 99px;
                height: 31px;
            }
        }
    }
    .c-line-link {
        margin-left: auto;
    }
    .c-menu-btn {
        position: relative;
        z-index: 55;
        margin-left: 31px;
        @include mq(sp){
            margin-left: 20px;
        }
    }
}

.g-footer {
    margin-top: 80px;
    padding: 0 20px;
    background: $brown;
    border-radius: 60px 0 0 0;
    color: #fff;
    @include mq(sp){
        border-radius: 30px 0 0 0;
    }
    &__in {
        position: relative;
        max-width: 1100px;
        margin: 0 auto;
        padding: 60px 0 40px;
        @include mq(sp){
            padding: 40px 30px 30px;
        }
    }
    &__pagetop {
        position: absolute;
        top: -40px;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        background: $brown;
        border-radius: 50%;
        color: #fff;
        font-size: 11px;
        font-family: $ff-js;
        font-weight: $fw-b;
        line-height: 1.1818;
        text-align: center;
        @include mq(pc){
            &:hover {
                opacity: 0.6;
            }
        }
        @include mq(sp){
            right: calc(50% - 40px);
        }
    }
    &__logo {
        display: block;
        width: 340px;
        height: 104px;
        @include mq(sp){
            width: 243px;
            height: 75px;
            margin: 0 auto;
        }
        .logo-w {
            width: 100%;
            height: 100%;
        }
    }
    &__box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        @include mq(sp){
            display: block;
            margin-top: 30px;
        }
    }
    &__text {
        line-height: 2.3571;
        @include mq(sp){
            font-size: 12px;
        }
    }
    &__nav {
        text-align: right;
        @include mq(sp){
            display: flex;
            flex-wrap: wrap;
            margin-top: 18px;
            text-align: left;
        }
        li {
            @include mq(pc){
                &:not(:nth-child(1)) {
                    margin-top: 15px;
                }
            }
            @include mq(sp){
                margin: 12px 12px 0 0;
            }
            a {
                display: inline-block;
                padding: 2px 14px 3px;
                border: 1px solid #fff;
                border-radius: 14px;
                line-height: 1.4286;
                color: inherit;
                @include mq(pc){
                    &:hover {
                        opacity: 0.6;
                    }
                }
                @include mq(sp){
                    padding: 2px 14px 3px;
                    font-size: 12px;
                }
            }
        }
    }
    &__copyright {
        margin-top: 30px;
        color: #b59c83;
        font-size: 10px;
        font-family: $ff-js;
        font-weight: $fw-r;
        line-height: 1.3;
        text-align: right;
    }
}
.c-menu-btn {
    position: relative;
    width: 35px;
    text-align: center;
    line-height: 0;
    transition: all 0.3s;
    @include mq(pc){
        &:hover {
            opacity: 0.6;
        }
    }
    @include mq(sp){
        width: 29px;
    }
    .menu-txt1 {
        width: 33.5px;
        height: 8.7px;
        @include mq(sp){
            width: 27.4px;
            height: 7.2px;
        }
    }
    .menu-txt2 {
        display: none;
        width: 36.5px;
        height: 8.3px;
        @include mq(sp){
            width: 29.8px;
            height: 6.8px;
        }
    }
    &__line {
        position: relative;
        height: 14px;
        margin-top: 10px;
        @include mq(sp){
            margin-top: 8px;
            height: 11px;
        }
        span {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;
            border-radius: 1px;
            background: $brown;
            transition: all 0.5s;
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2) {
                bottom: 0;
            }
        }
    }
}
.c-menu {
    // display: none;
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 30;
    width: 660px;
    height: 100vh;
    background: $brown;
    transition: right 1s;
    overflow-y: scroll;
    pointer-events: initial;
    @include mq(sp){
        width: 100%;
        transition: right 0.7s ease;
    }
    &__in {
        padding: 62px 0 62px 60px;
        @include mq(sp){
            padding: 62px 30px 120px;
        }
    }
    &__item {
        &--news {
            .c-menu__item__nav li {
                @include mq(sp){
                    width: 155px;
                }
            }
        }
        &--shoplist {
            margin-top: 50px;
            @include mq(sp){
                margin-top: 30px;
            }
        }
        &__title {
            color: #fff;
            font-family: $ff-js;
            font-weight: $fw-b;
            font-size: 30px;
            @include mq(sp){
                font-size: 20px;
            }
        }
        &__nav {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            li {
                @include mq(pc){
                    width: 260px;
                    &:not(:nth-child(-n+2)) {
                        margin-top: 20px;
                    }
                    &:nth-child(2n) {
                        margin-left: 20px;
                    }
                }
                @include mq(sp){
                    width: 100%;
                    &:not(:nth-child(1)) {
                        margin-top: 10px;
                    }
                }
                a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: #FAF8F0;
                    padding: 16px 20px 18px 30px;
                    border-radius: 40px;
                    font-size: 16px;
                    line-height: 1;
                    @include mq(pc){
                        &:hover {
                            opacity: 0.6;
                        }
                    }
                    @include mq(sp){
                        padding: 12px 10px 12px 15px;
                        font-size: 12px;
                    }
                    svg {
                        width: 23px;
                        height: 23px;
                        @include mq(sp){
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }
}
body.menu-open {
    overflow: hidden;
    .c-menu-btn {
        .menu-txt1 {
            display: none;
        }
        .menu-txt2 {
            display: block;
        }
        .c-menu-btn__line span {
            background: #fff;
            &:nth-child(1) {
                transform-origin: 0 0;
                transform: rotate(20deg);
            }
            &:nth-child(2) {
                transform-origin: 0 0;
                transform: rotate(-20deg);
            }
        }
    }

    .c-menu {
        right: 0;
    }
}


.c-line-link {
    display: block;
    @include mq(pc){
        &:hover {
            opacity: 0.6;
        }
    }
    .ico-line {
        width: 35px;
        height: 35px;
        @include mq(sp){
            width: 29px;
            height: 29px;
        }
    }
}


.c-main {
    padding: 60px 20px 0;
    @include mq(sp){
        padding-top: 80px;
    }

    &__in {
        max-width: 1100px;
        margin: 0 auto;
    }
}

.c-cont-header {
    text-align: center;
    line-height: 1;
    &__txten {
        font-family: $ff-js;
        font-weight: $fw-b;
        font-size: 50px;
        letter-spacing: 0;
        @include mq(sp){
            font-size: 30px;
        }
    }
    &__txtja {
        margin-top: 5px;
        font-weight: $fw-m;
        @include mq(sp){
            margin-top: 3px;
        }
    }
}

.c-btn-top {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 290px;
    height: 70px;
    margin: 40px auto 0;
    background: #fff;
    border-radius: 45px;
    @include mq(pc){
        &:hover {
            opacity: 0.6;
        }
    }
    @include mq(sp){
        width: 250px;
        height: 40px;
        margin: 20px auto 0;
    }
    .ico-arr-left {
        position: absolute;
        width: 23px;
        height: 23px;
        left: 20px;
        top: calc(50% - 12px);
        @include mq(sp){
            left: 12px;
        }
    }
    &__txt {
        font-family: $ff-js;
        font-weight: $fw-r;
        font-size: 20px;
        line-height: 1;
        @include mq(sp){
            font-size: 14px;
        }
    }
}

.news-detail-list {
    margin-top: 53px;
    @include mq(sp){
        margin-top: 30px;
    }
}
.news-detail {
    padding: 60px 80px;
    border-radius: 60px;
    background: #fff;
    @include mq(sp){
        padding: 30px 25px;
        border-radius: 30px;
    }
    &:not(:nth-child(1)) {
        margin-top: 40px;
        @include mq(sp){
            margin-top: 20px;
        }
    }
    &__header {
        &__date {
            span {
                display: inline-block;
                padding: 3px 16px 4px 12px;
                background: $brown;
                border-radius: 0px 13.5px 13.5px 0px;
                color: #fff;
                line-height: 1.42857143;
                @include mq(sp){
                    font-size: 12px;
                }
            }
        }
        &__title {
            margin-top: 10px;
            font-size: 24px;
            line-height: 1.45833333;
            @include mq(sp){
                font-size: 16px;
                line-height: 1.5;
            }
        }
    }
    &__body {
        margin-top: 30px;
        line-height: 1.4286;
        @include mq(sp){
            margin-top: 15px;
            font-size: 12px;
        }
    }
}

.shop-detail {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
    padding: 50px 80px;
    background: #fff;
    border-radius: 60px;
    @include mq(sp){
        display: block;
        margin-top: 30px;
        padding: 25px 30px;
        border-radius: 30px;
    }
    &__pic {
        width: 47.9%;
        @include mq(sp){
            width: 100%;
        }
        img {
            border-radius: 8.9%;
        }
    }
    &__body {
        width: 46.8%;
        @include mq(sp){
            width: 100%;
            margin-top: 15px;
        }
        .shop-name {
            font-size: 24px;
            line-height: 1.4583;
            @include mq(sp){
                font-size: 20px;
            }
        }
        .shop-tb-list {
            margin-top: 20px;
            @include mq(sp){
                margin-top: 15px;
            }
        }
        .shop-tb {
            @include mq(sp){
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }
            &:not(:nth-child(1)) {
                margin-top: 20px;
                @include mq(sp){
                    margin-top: 12px;
                }
            }
            &__title {
                @include mq(sp){
                    width: 80px;
                }
                span {
                    display: inline-block;
                    padding: 3px 14px 4px;
                    background: #BF8B55;
                    border-radius: 14px;
                    color: #fff;
                    line-height: 1.42857143;
                    @include mq(sp){
                        width: 100%;
                        font-size: 12px;
                        padding: 3px 0 4px;
                        text-align: center;
                    }
                }
            }
            &__text {
                margin-top: 8px;
                line-height: 1.42857143;
                @include mq(sp){
                    width: calc(100% - 95px);
                    margin-top: 0;
                    font-size: 12px;
                }
                a {
                    @include mq(pc){
                        &[href^=tel] {
                            pointer-events: none;
                        }
                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
    }
}